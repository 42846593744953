import React, { useState } from "react"
import { graphql } from "gatsby"
import { Container, Row, Col, Button, Card, Form } from "react-bootstrap"
import { useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import { Formik } from "formik"
import * as Yup from "yup"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
// import CallIcon from "../components/callIcon"

// import { APREMIA_PHONE_FORMATTED } from "../params"
import { submitMessageRequest } from "../services"

import Map from "../images/usa-map.png"
import Sign from "../images/apremia-sign.png"
import EnrollBanner from "../images/stock-image-36.jpg"
import AttentionIcon from "../images/attention-24-7.webp"
import HandsIcon from "../images/component-1-2.webp"
import PriceIcon from "../images/component-2-1.webp"
import DoctorIcon from "../images/component-4-1.webp"
import DrugstoreIcon from "../images/component-5-1.webp"
import ValueIcon from "../images/component-8-1.webp"
import SickIcon from "../images/component-7-1.webp"
import PregnantIcon from "../images/component-9-1.webp"
import Seal from "../images/sello.webp"
import HeadingBg from "../images/capa-4.webp"
import HeadingMobileBg from "../images/capa-4-mobile.webp"
import ObamacareLogo from "../images/obamacare.svg"
import SuccessSrc from "../images/success.png"

import { fbq } from '@hutsoninc/gatsby-plugin-facebook-pixel'

const HeadingWrapper = styled.div`
  background-image: url("${HeadingBg}");
  background-size: 1280px auto;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
  
  @media (max-width: 820px) {
    background-size: auto 350px;
  }

  @media (max-width: 767px) {
    background-image: url("${HeadingMobileBg}");
    background-size: 100% auto;
    padding-top: 230px !important;
  }

  @media (max-width: 375px) {
    padding-top: 215px !important;
  }
`

const MapImage = styled.img`
  width: 100%;
  @media (max-width: 820px) {
    margin-bottom: 2.5rem;
  }
`

const SignImage = styled.img`
  width: 320px;
`

const IconImage = styled.img`
  height: 70px;
  width: 70px;
`

const ObamacareLogoImage = styled(ObamacareLogo)`
  width: 70px;
`

const EnrollmentBanner = styled.div`
  background-color: ${props => props.theme.colors["body-color"]};
  background-image: url("${EnrollBanner}");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;

  @media (max-width: 820px) {
    background-size: auto 100%;
  }

  @media (max-width: 414px) {
    background-size: auto 180px;
  }
`

const EnrollmentBannerIcon = styled.img`
  height: 70px;
  width: 70px;
  margin-bottom: 0.3rem;
`

const EnrollmentBannerCol = styled(Col)`
  position: relative;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    height: 50%;
    border: 1px solid #ffffff20;
    border-top: 0 none;
    border-bottom: 0 none;
    @media (max-width: 414px) {
      display: none;
    }
  }
`

const SealImage = styled.img`
  width: 104px;
  height: 119px;
  margin: 2.6rem 0 1.5rem;

  @media (max-width: 767px) {
    position: absolute;
    top: 0;
    left: 0;
  }
`

const AdCard = styled(Card)`
  min-height: 200px;
  padding: 0 1rem;
  background-color: ${props => props.$colored ? '#e4002b' : '#fff'};

  @media (max-width: 820px) {
    min-height: auto;
    box-shadow: none;
    background-color: ${props => props.$colored ? '#e4002b' : 'transparent'};
    margin-top: ${props => props.$colored ? '2rem' : '0'};
  }
`

const QuoteFormSentMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: calc(100% - 15px);
  width: calc(100% - 15px);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 2rem;

  & > img {
    height: 120px;
    margin-bottom: 1rem;
  }
`

const initialValues = {
  name: "",
  phoneNumber: "",
  email: "",
  callTime: "Mañana 8:00 am - 12:00 pm",
  message: "",
}

// RegEx for phone number validation
const phoneRegExp = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

const EnrollObamacare = () => {
  const { t } = useTranslation()

  const [ visibleQuoteFormSentMessage, setVisibleQuoteFormSentMessage ] = useState(false)
  const [ sending, setSending ] = useState(false)
  const [ formError, setFormError ] = useState(null)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("required_field_error")),
    email: Yup.string().email(t("format_invalid_field_error")).required(t("required_field_error")),
    phoneNumber: Yup.string().matches(
      phoneRegExp,
      t("format_invalid_field_error")
    ).required(t("required_field_error")),
    message: Yup.string(),
  })

  const ads = [
    {
      icon: HandsIcon,
      content: 'Obtén asesoría personalizada (siempre gratis) y acompañamiento cada vez que nos necesites.',
    },
    {
      icon: PriceIcon,
      content: 'Descubre los mejores precios de seguros médicos.',
    },
    {
      icon: AttentionIcon,
      content: 'Llámanos en cualquier momento para atenderte y representarte, estés tomando café en casa o en una emergencia familiar.',
    },
    {
      icon: DoctorIcon,
      content: 'Pídenos tus citas médicas fácilmente con tu médico primario y especialistas.',
    },
    {
      icon: DrugstoreIcon,
      content: 'Pregúntanos sobre las farmacias cerca de ti que aceptan tu seguro médico.',
    },
  ]

  const renderMapHeading = (wrapperProps) => (
    <div {...wrapperProps}>
      <IconImage src={AttentionIcon}/>
      <h3 className="text-danger">Asesoría personalizada gratis, a toda hora en 28 estados.</h3>
    </div>
  )

  return (
    <Layout
      hideNavbarMenu
      hideFooter
      disableNavbarMenuResponsiveLogo
    >
      <SEO title={t("obamacare_enrollment")} />

      {/* ----- Heading ----- */}
      <HeadingWrapper className="py-5 py-lg-6">
        <Container>
          <Row>
            <Col md={5} xl={4}>
              <h1 className="d-none d-md-block text-white">La tranquilidad de tu familia no tiene precio.</h1>
              <h2 className="text-center d-md-none mb-2">La tranquilidad de tu familia<br/> no tiene precio.</h2>

              <h3 className="d-none d-md-block text-white">En Apremia te la aseguramos.</h3>
              <h4 className="text-center d-md-none">En Apremia te la aseguramos.</h4>

              <SealImage src={Seal}/>

              <h3 className="d-none d-md-block">
                Para inscribirse en Obamacare o cotizar los mejores seguros médicos, envía tus datos para asesorarte en <span className="text-danger">menos de 24 horas</span>.
              </h3>

              <hr className="d-md-none"/>
              <h4 className="text-center d-md-none">
                Para inscribirse en Obamacare o cotizar los mejores seguros médicos, envía tus datos para asesorarte en <span className="text-danger">menos de 24 horas</span>.
              </h4>
            </Col>

            <Col className="d-flex flex-column justify-content-end ps-md-6">
              <div className="d-none d-md-block" style={{ minHeight: '275px' }}></div>
              <div style={{ paddingTop: '100px', marginTop: '-100px' }} id="quote-form"></div>
              <Card>
                <Card.Body className="p-4">
                  <p className="fw-bold text-danger">
                    {formError}
                  </p>

                  {visibleQuoteFormSentMessage && (
                    <QuoteFormSentMessage onClick={() => setVisibleQuoteFormSentMessage(false)}>
                      <img src={SuccessSrc} alt="success" />
                      <h2>{t("form_sent_title")}</h2>
                      <h4>{t("form_sent_subtitle")}</h4>
                    </QuoteFormSentMessage>
                  )}

                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {
                      try {
                        fbq('track', 'Lead')
                        setSending(true)
                        await submitMessageRequest(values);
                        setVisibleQuoteFormSentMessage(true);
                      } catch (err) {
                        setFormError('Tuvimos un problema guardando su información, por favor intente nuevamente')
                      }
                      finally {
                        setSending(false)
                      }
                    }}
                  >
                    {props => {
                      const {
                        values,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleSubmit,
                      } = props

                      return (
                        <Form onSubmit={handleSubmit}>
                          <fieldset disabled={isSubmitting}>
                            <Row>
                              <Form.Group as={Col} md={6} className="mb-3" controlId="formName">
                                <Form.Label className="required">{t("form_name")}</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="name"
                                  value={values.name}
                                  onChange={handleChange}
                                  isInvalid={!!errors.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.name}
                                </Form.Control.Feedback>
                              </Form.Group>

                              <Form.Group as={Col} className="mb-3" controlId="formPhoneNumber">
                                <Form.Label className="required">{t("form_phone_number")}</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="phoneNumber"
                                  value={values.phoneNumber}
                                  onChange={handleChange}
                                  isInvalid={!!errors.phoneNumber}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.phoneNumber}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Row>

                            <Form.Group className="mb-3" controlId="formEmail">
                              <Form.Label className="required">{t("form_email")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                isInvalid={!!errors.email}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.email}
                              </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formCallTime">
                              <Form.Label>¿Cúal es el mejor horario para llamarte?</Form.Label>
                              <Form.Select
                                name="callTime"
                                value={values.callTime}
                                onChange={handleChange}
                              >
                                <option value="De 8am a 10am">De 8am a 10am</option>
                                <option value="De 10am a 12pm">De 10am a 12pm</option>
                                <option value="De 12pm a 2pm">De 12pm a 2pm</option>
                                <option value="De 2pm - 5pm">De 2pm - 5pm</option>
                                <option value="De 6pm - 8pm">De 2pm - 5pm</option>
                              </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formMessage">
                              <Form.Label>{t("form_message")}</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                name="message"
                                value={values.message}
                                onChange={handleChange}
                                isInvalid={!!errors.message}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.message}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </fieldset>

                          <Button type="submit" disabled={isSubmitting}>
                            {sending ? 'Enviando...' : '¡Enviar Ahora!'}
                          </Button>
                        </Form>
                      )
                    }}
                  </Formik>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </HeadingWrapper>
      {/* ----- Heading (end) ----- */}

      {/* ----- Map ----- */}
      <div className="bg-white py-5 py-lg-6">
        <Container className="equalize-padding-x gx-0 gx-md-3">
          {renderMapHeading({ className: "d-lg-none text-center" })}

          <Row>
            <Col lg={7}>
              <MapImage src={Map} />
            </Col>
            <Col className="d-flex flex-column justify-content-center ps-lg-5">
              {renderMapHeading({ className: "d-none d-lg-block" })}

              <ul className="ul ul-column-2">
                <li>Alabama (AL)</li>
                <li>Arizona (AZ)</li>
                <li>Arkansas (AR)</li>
                <li>California (CA)</li>
                <li>Carolina del Norte (NC)</li>
                <li>Carolina del Sur (SC)</li>
                <li>Colorado (CO)</li>
                <li>Florida (FL)</li>
                <li>Georgia (GA)</li>
                <li>Illinois (IL)</li>
                <li>Indiana (IN)</li>
                <li>Iowa (IA)</li>
                <li>Kansas (KS)</li>
                <li>Louisiana (LA)</li>
                <li>Maryland (MD)</li>
                <li>Míchigan (MI)</li>
                <li>Misisipi (MS)</li>
                <li>Misuri (MO)</li>
                <li>Nebraska (NE)</li>
                <li>Nueva Jersey (NJ)</li>
                <li>Nuevo México (NM)</li>
                <li>Ohio (OH)</li>
                <li>Oklahoma (OK)</li>
                <li>Tennessee (TN)</li>
                <li>Texas (TX)</li>
                <li>Utah (UT)</li>
                <li>Virginia (VA)</li>
                <li>Wisconsin (WI)</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      {/* ----- Map (end) ----- */}

      {/* ----- Enrollment banner ----- */}
      <EnrollmentBanner>
        <Container className="pt-2 pb-4 py-md-6 text-center">
          <ObamacareLogoImage/>
          <h2 className="text-white lh-sm mb-5">
            Inscribirse en Obamacare<br/>
            <small>es más fácil que abrir una cuenta en Facebook</small>
          </h2>

          <Row className="text-white" xs={1} md={3}>
            <Col>
              <EnrollmentBannerIcon src={ValueIcon}/>
              <p>Primas y deducibles<br className="d-none d-md-block"/> bajos</p>
            </Col>
            <EnrollmentBannerCol>
              <EnrollmentBannerIcon src={SickIcon}/>
              <p>Cubre enfermedades<br className="d-none d-md-block"/> preexistentes</p>
            </EnrollmentBannerCol>
            <Col>
              <EnrollmentBannerIcon src={PregnantIcon}/>
              <p>Acepta mujeres<br className="d-none d-md-block"/> embarazadas</p>
            </Col>
          </Row>

          <Button href="#quote-form" variant="light" className="text-danger bg-white mt-4">¡Aplica ahora!</Button>
        </Container>
      </EnrollmentBanner>
      {/* ----- Enrollment banner (end) ----- */}

      {/* ----- Ads and footer ----- */}
      <div className="py-5 py-lg-6">
        <Container className="text-center">
          <h2 className="mb-3 mb-lg-5">¡Los Mejores Seguros Médicos cerca de ti!</h2>

          <Row>
            {ads.map((item, index) => (
              <Col key={index} lg={4} className="mb-lg-4">
                <AdCard $colored={item.colored}>
                  <Card.Body style={{ display: item.display ? 'flex' : 'block', alignItems: 'center', justifyContent: 'center' }}>
                    {item.content && (
                      <>
                        <IconImage src={item.icon} className="mb-2"/>
                        <Card.Text>
                          {item.content}
                        </Card.Text>
                      </>
                    )}

                    {item.display}
                  </Card.Body>
                </AdCard>
              </Col>
            ))}
          </Row>

          <hr className="my-5"/>

          <h2>
            Lo importante es que estés tranquilo.<br/>
            Si tú ganas con tu seguro médico,
          </h2>
          <SignImage src={Sign}/>

          <p className="mt-4">
            {t("copyright")} Apremia © {new Date().getFullYear()}
          </p>
        </Container>
      </div>
      {/* ----- Ads and footer (end) ----- */}
    </Layout>
  )
}

export default EnrollObamacare

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
